const getFilters = () => {
    // English
    const filtersEN = ["Order", "Order Number", "Order ID", "Order No.", "Order Ref", "Order Reference", "#"];

    // German
    const filtersDE = ["Auftrag", "Bestellung", "#", "Order-ID", "OrderId", "Order Id", "Bestellnummer", "Bestellnummern", "Auftragsnummer", "Auftragsnummer"];

    // French
    const filtersFR = ["Commande", "Numéro de Commande", "ID de Commande", "Référence de Commande", "#"];

    // Spanish
    const filtersES = ["Pedido", "Número de Pedido", "ID de Pedido", "Referencia de Pedido", "#"];

    // Dutch
    const filtersNL = ["Bestelling", "Bestelnummer", "Bestelling-ID", "Referentie van de bestelling", "#"];

    // Italian
    const filtersIT = ["Ordine", "Numero d'ordine", "ID ordine", "Riferimento ordine", "#"];

    // Polish
    const filtersPL = ["Zamówienie", "Numer zamówienia", "ID zamówienia", "Numer referencyjny zamówienia", "#"];

    const filters = [...filtersEN, ...filtersDE, ...filtersFR, ...filtersES, ...filtersNL, ...filtersIT, ...filtersPL];

    return filters;
}




const detectOrderNumbers = (ticketSubject, ticketDescription) => {


    // Possible filters
    const filters = getFilters();
    
    // Try to find a match in the ticketSubject first
    for(let i = 0; i < filters.length; i++) {
        const match = ticketSubject.match(new RegExp(`(${filters[i]}) (\\d+(-\\d+)*)`, 'i'));
        if(match) {
            return createOrderNumberObject(match[2]);
        }
    }

    // If no match is found in the ticketSubject, search in the ticketDescription
    for(let i = 0; i < filters.length; i++) {
        const match = ticketDescription.match(new RegExp(`(${filters[i]}) (\\d+(-\\d+)*)`, 'i'));
        if(match) {
            return createOrderNumberObject(match[2]);
        }
    }

    // Return default if no matches found
    return {
        orderNumberFound: false,
        orderNumber: null,
        isOrderId: false,
        isExternalOrderId: false
    };
}

const createOrderNumberObject = (orderNumber) => {
    const isOrderId = /^\d+$/.test(orderNumber) && orderNumber.length <= 10;
    return {
        orderNumberFound: true,
        orderNumber: orderNumber,
        isOrderId: isOrderId,
        isExternalOrderId: !isOrderId
    };
};

export { detectOrderNumbers };
