import { useEffect } from 'react';

export default function useFetchReferencesList(referencesList, currentOrder, client, getOrderReferences, setIsLoadingDetail, setReferencedOrdersList) {
    useEffect(() => {
        async function fetchReferencesList() {
            try {
                if(referencesList.length === 0) return;
                if(!currentOrder) return;
                setIsLoadingDetail(true);

                const referencesIds = referencesList.map(r => r.referenceOrderId).join(",");
                const searchParameters = {orderIds: referencesIds};
                const referenceOrdersResponse = await client.request.invoke('getAllOrders', searchParameters);

                const referencedOrdersList = referenceOrdersResponse.response.entries || [];
                setReferencedOrdersList(referencedOrdersList);

                const referencedOrdersReferences = referencedOrdersList.map(order => getOrderReferences(order)).flat();

                let missingReferencesIds = referencedOrdersReferences.map(r => r.referenceOrderId).filter(referenceOrderId => !referencedOrdersList.find(order => String(order.id) === referenceOrderId));
                missingReferencesIds = missingReferencesIds.filter(r => r !== String(currentOrder.id));

                if(missingReferencesIds.length > 0) {
                    const missingSearchParameters = {orderIds: missingReferencesIds.join(",")};
                    const missingReferenceOrdersResponse = await client.request.invoke('getAllOrders', missingSearchParameters);

                    const missingReferencedOrdersList = missingReferenceOrdersResponse.response.entries || [];
                    setReferencedOrdersList([...referencedOrdersList, ...missingReferencedOrdersList]);
                }

                setIsLoadingDetail(false);
            } catch (error) {
                setIsLoadingDetail(false);
                await client.interface.trigger("showNotify", { type: "danger", message: error.message || 'Something went wrong!' });
                console.error('Error fetching references list:', error);
            }
        }
        fetchReferencesList();
    }, [referencesList, currentOrder, client, getOrderReferences, setIsLoadingDetail, setReferencedOrdersList]);
}
