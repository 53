import React, { useEffect, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next';
import ReactDOM from "react-dom";
import { FwTooltip, FwModal, FwButton, FwTextarea, FwSelect, FwSelectOption, FwModalContent, FwModalFooter } from "@freshworks/crayons/react";

const OrderStatus = ({ order, onOrderStatusChange, statuses, client }) => {

    const { t } = useTranslation();

    const modalRef = useRef();
    const selectRef = useRef();

    const [selected_statuses, setSelectedStatuses] = useState([]);
    const [statusList, setStatusList] = useState([]);
    const [currentStatusName, setCurrentStatusName] = useState('');
    const [currentStatusColor, setCurrentStatusColor] = useState('');
    const [isLoading, setIsLoading] = useState(true);

    const [selectedStatus, setSelectedStatus] = useState("-");

    // This useEffect block will be executed whenever `order` or `statusList` changes.
    // If the `statusId` in `order` matches any id in `statusList`, it will be selected.
    // Otherwise, "-" will be selected.
    useEffect(() => {
        if (order && statusList.some(status => status.id === order.statusId)) {
        setSelectedStatus(order.statusId);
        } else {
        setSelectedStatus("-");
        }
    }, [order, statusList]);

    const handleChange = (event) => {
        console.log("handleChange", event.target.value);
        setSelectedStatus(event.target.value);
    };


    //ModalContent ref
    //     (alias) const FwModalContent: ForwardRefExoticComponent<Pick<any, string | number | symbol> & RefAttributes<HTMLFwModalContentElement>>
    // import FwModalContent
    const modalContentRef = useRef();

    useEffect(() => {
        if (statuses && statuses.length && order && order.statusId) {
            const currentStatus = statuses.find(status => status.id == order.statusId);
            setCurrentStatusName(currentStatus ? currentStatus.name : 'X');

            if (currentStatus && currentStatus.id < 5) { setCurrentStatusColor('bg-gray-300'); return; }
            if (currentStatus && currentStatus.id < 7) { setCurrentStatusColor('bg-yellow-300'); return; }
            if (currentStatus && currentStatus.id < 9) { setCurrentStatusColor('bg-green-300'); return; }
            setCurrentStatusColor('bg-gray-300');

        }
    }, [order, statuses]);

    const getSelectedStatuses = async () => {
        try {
            const zafSettings = await client.zafClient.metadata();
            let selected_statusesData = zafSettings.settings.selected_statuses;

            //trim selected_statusesData
            selected_statusesData = selected_statusesData.trim();

            selected_statusesData = selected_statusesData.split(';').map(status => status.trim());

            console.log("selected_statusesData ARRAY", selected_statusesData);

            setSelectedStatuses(selected_statusesData || []);
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching selected statuses:', error);
            setIsLoading(false);
        }
    };

    useEffect(() => {

        if (selected_statuses && selected_statuses.length > 0) {
            console.log("selected_statuses in useEffect", selected_statuses);
            const statusListData = statuses.filter((status) =>
                selected_statuses.some((selected_status) => String(selected_status) === String(status.id))
            ).map(status => ({ id: status.id, name: status.name }));

            console.log("setStatusList in useEffect", statusListData)

            setStatusList(statusListData);
        }
    }, [selected_statuses, statuses]);


    useEffect(() => {

        getSelectedStatuses();

        console.log("UseEffect getSelectedStatuses");

        setTimeout(() => {
            if (modalContentRef.current && modalContentRef.current.shadowRoot) {
                const shadowRoot = modalContentRef.current.shadowRoot;
                const style = document.createElement('style');
                style.textContent = '.content { padding-inline: 3px !important; }';
                shadowRoot.appendChild(style);
            }
        }, 100);

    }, [client]);

    const handleSubmitModal = async () => {

        if (order && order.id && selectedStatus && selectedStatus !== '-') {
            const orderStatus = { 'orderId': order.id, 'statusId': selectedStatus };
            onOrderStatusChange(orderStatus);
        }
        if (modalRef.current) {
            modalRef.current.close();
        }
    };

    const handleCloseModal = () => {
        if (modalRef.current) {
            console.log("Close modal");
            modalRef.current.close();
        }
    };

    const handleOpenModal = () => {
        if (modalRef.current) {
            console.log("Open modal");
            modalRef.current.open();
        }
    };

    // if (isLoading) {
    //     return <div>Loading...</div>;
    // }

    return (
        <div className="w-full">
            {/* <FwModal ref={modalRef} size="large" titleText="" slider onFwClose={handleCloseModal} hideFooter="true">
                <FwModalContent ref={modalContentRef}>
                    <div>TEST</div>
                </FwModalContent>
            </FwModal> */}
            <FwModal ref={modalRef} data-test-id="select-status-modal" id='statuses-modal' size="large" titleText="" slider onFwClose={handleCloseModal} hideFooter="true">
                <FwModalContent ref={modalContentRef}>
                    <div>
                        <div data-test-id="select-status-modal-headline" className="text-lg font-bold mb-4">{t("change_order_status_title")}</div>

                        {statusList.length === 0 && <div className="text-sm text-gray-500 mb-4">{t("no_statuses_selected")}</div>}

                 

                        {statusList.length > 0 &&
                            <select 
                            data-test-id="order-status-select" 
                            className="block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-gray-500 focus:border-gray-500 sm:text-sm" 
                            required={false} 
                            value={selectedStatus} 
                            onChange={handleChange}
                            >
                            <option value="-">-</option>
                            {statusList.map((status) => (
                                <option key={status.id} value={status.id}>
                                {status.name}
                                </option>
                            ))}
                            </select>
                            
                         }

                        {/* <FwSelect data-test-id="order-status-select" ref={selectRef} label={t('label_select_status')} required="false">
                                {statusList.map((status) => (
                                    <FwSelectOption key={status.id} value={status.id} selected={order && status.id === order.statusId}>{status.name}</FwSelectOption>
                                ))}
                            </FwSelect>  */}

                        <div className="flex justify-between items-center mb-4 mt-5">
                            <FwButton color="text" onClick={handleCloseModal}>{t('close')}</FwButton>
                            {statusList.length > 0 &&
                                <FwButton data-test-id="submit-statuschange-button" onClick={handleSubmitModal}>OK</FwButton>
                            }
                        </div> 

                    </div>
                </FwModalContent>
                <FwModalFooter></FwModalFooter>
            </FwModal>
            <FwTooltip content={t("change_order_status_title")} placement="bottom">
            <div onClick={handleOpenModal} data-test-id="order-status-name" className={`${currentStatusColor} p-1 h-full w-full shadow-sm statusText text-center font-bold text-gray-700  mr-1 flex items-center justify-center cursor-pointer`}>
                    {currentStatusName}
                </div>
            </FwTooltip>

             {/* <button onClick={handleOpenModal} data-test-id="order-status-name" className={`${currentStatusColor} p-1 h-full w-full shadow-sm statusText text-center font-bold text-gray-700  mr-1 flex items-center justify-center`}>
                    {currentStatusName}
                </button> */}

            {/* <div onClick={handleOpenModal} data-test-id="order-status-name" className={`${currentStatusColor} p-1 h-full w-full shadow-sm statusText text-center font-bold text-gray-700  mr-1 flex items-center justify-center cursor-pointer`}>
                    {currentStatusName}
                </div> */}

        </div>
    );
};

export default OrderStatus;
