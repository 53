import React, { useState, useEffect, useLayoutEffect } from 'react';
import './App.css';
import OrderManager from './components/OrderManager';
import './i18n';
import { useTranslation } from 'react-i18next';
import ExtendedZAFClient from './classes/ExtendedZAFClient'; 

const App = () => {

  const [loaded, setLoaded] = useState(false);
  const [child, setChild] = useState(<h3>App is loading</h3>)
  const { t, i18n } = useTranslation();

  const [client, setClient] = useState(null);

  useLayoutEffect(() => {
    const browserLanguage = (navigator.language || navigator.userLanguage).split('-')[0];
    i18n.changeLanguage(browserLanguage);
  }, [i18n]);


  useLayoutEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://static.zdassets.com/zendesk_app_framework_sdk/2.0/zaf_sdk.min.js';
    script.addEventListener('load', () => setLoaded(true));
    script.defer = true;
    document.head.appendChild(script);

    // Ensure to remove the script tag after component unmounts to avoid memory leaks
    return () => {
      document.head.removeChild(script);
    };
  }, []);

  // useLayoutEffect(() => {
  //   const script = document.createElement('script');
  //   script.src = '{{{appclient}}}';
  //   script.src = 'https://static.zdassets.com/zendesk_app_framework_sdk/2.0/zaf_sdk.min.js';
  //   script.addEventListener('load', () => setLoaded(true));
  //   script.defer = true;
  //   document.head.appendChild(script);
  // }, []);

  useLayoutEffect(() => {
    if (!loaded) return
    const extendedClient = new ExtendedZAFClient();
    console.log("EXTENDED ZAF CLIENT", extendedClient);
    //const client = ZAFClient.init();
    //console.log("ZAF CLIENT", client);

    //extendedClient.zafClient.invoke("notify", "Ticket successfully updated!");
    extendedClient.zafClient.invoke("resize", { width: "100%", height: "800px" });

    console.log("ZAF CLIENT", extendedClient);
    setChild((<OrderManager client={extendedClient} />))

    // app.initialized().then((client) => {
    //   console.log("App Initialized", client);
    //   client.instance.resize({ height: "950px" });
    //   //setChild((<HelloUser client={client} />))
    //   //setChild((<BookmarkedTickets client={client} />))
    //   setChild((<OrderManager client={client} />))
      

    // }).catch((error) => {
    //   console.error("App Initializing error", error);
    // });
  }, [loaded])

  return (
    <div>
      {child}
    </div>
  )
}

export default App;
