
// const isIdDelimiter = (char) => {
//     const charCode = char.charCodeAt(0);
//     return !(charCode >= 48 && charCode <= 57) && !(charCode >= 65 && charCode <= 90) && !(charCode >= 97 && charCode <= 122);
// };

// const extractOrder = (ordersList, ticketTitle, ticketDescription) => {
//     const combinedString = `${ticketTitle} ${ticketDescription}`.toLowerCase();

//     for (const order of ordersList) {
//         let orderIds = [String(order.id).toLowerCase()];

//         const property = order.properties && order.properties.find(p => p.typeId === 7);
//         if (property) {
//             orderIds.push(String(property.value).toLowerCase());
//         }

//         for (const orderId of orderIds) {
//             const index = combinedString.indexOf(orderId);

//             if (index !== -1) {
//                 const nextCharIndex = index + orderId.length;
//                 if (nextCharIndex >= combinedString.length || isIdDelimiter(combinedString[nextCharIndex])) {
//                     return { orderFound: true, orderId: order.id };
//                 }
//             }
//         }
//     }

//     return { orderFound: false };
// };

// export { extractOrder };


const isIdDelimiter = (char) => {
    const invalidChars = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '/'];
    return !invalidChars.includes(char);
};

const extractOrder = (ordersList, ticketTitle, ticketDescription) => {
    const combinedString = `${ticketTitle} ${ticketDescription}`.toLowerCase();

    for (const order of ordersList) {
        let orderIds = [String(order.id).toLowerCase()];

        const property = order.properties && order.properties.find(p => p.typeId === 7);
        if (property) {
            orderIds.push(String(property.value).toLowerCase());
        }

        for (const orderId of orderIds) {
            const index = combinedString.indexOf(orderId);

            if (index !== -1) {
                const prevChar = combinedString[index - 1] || '';
                const nextChar = combinedString[index + orderId.length] || '';

                if (isIdDelimiter(prevChar) && isIdDelimiter(nextChar)) {
                    return { orderFound: true, orderId: order.id };
                }
            }
        }
    }

    return { orderFound: false };
};

export { extractOrder };

