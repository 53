import React from 'react'
import { FwSkeleton } from '@freshworks/crayons/react';

const OrderSkeleton = ({isDetail}) => {

    console.log("isDetail", isDetail);
  return (
    <div className="mb-4 mt-1">
        <div className="mb-0.5"><FwSkeleton variant="rect" height="35px" count={1}></FwSkeleton></div>
        <div className="mb-3"><FwSkeleton variant="rect" height="24px" count={1}></FwSkeleton></div>
        <div className="grid grid-cols-2 gap-4 mt-4 pl-2">
            <div className="col-span-1">
                <FwSkeleton variant="rect" height="22px" count={2}></FwSkeleton>
            </div>
            <div className="col-span-1 pr-2">
                <FwSkeleton variant="rect" height="22px" count={2}></FwSkeleton>
            </div>
        </div>
        { isDetail === 'true' && (
            <div className="grid grid-cols-2 gap-4 pl-2 mt-2 mb-4">
            <div className="col-span-1">
                <FwSkeleton variant="rect" height="22px" count={3}></FwSkeleton>
            </div>
            <div className="col-span-1 pr-2">
                <FwSkeleton variant="rect" height="22px" count={3}></FwSkeleton>
            </div>
        </div>
        )}
        <div className="grid grid-cols-1 mt-3 pl-2">
            <div className="col-span-1 w-2/3 mb-3">
                <FwSkeleton variant="rect" height="20px" count={1}></FwSkeleton>
            </div>
            <div className="col-span-1 w-10/12 mt-1">
                <FwSkeleton variant="rect" height="45px" count={1}></FwSkeleton>
            </div>
        </div>
        <div className="flex space-x-2 pl-2">
            <div className="w-7/12 mt-4">
                    <FwSkeleton variant="rect" height="20px" count={1}></FwSkeleton>
            </div>
            <div className="w-5/12 mt-4 ml-4 ">
                <div className="float-right pr-2"><FwSkeleton variant="circle" height="20px" width="20px" count={1}></FwSkeleton></div>
            </div>
        </div>
        { isDetail === 'true' && (
            <div className="mt-4">
                <div className="flex space-x-2 pl-2 mt-4">
                <div className="w-7/12">
                        <FwSkeleton variant="rect" height="20px" count={1}></FwSkeleton>
                </div>
                <div className="w-5/12 ml-4">
                    <div className="float-right pr-2"><FwSkeleton variant="circle" height="20px" width="20px" count={1}></FwSkeleton></div>
                </div>
            </div>
            <div className="flex space-x-2 pl-2 mt-4">
                <div className="w-7/12">
                        <FwSkeleton variant="rect" height="20px" count={1}></FwSkeleton>
                </div>
                <div className="w-5/12 ml-4">
                    <div className="float-right pr-2"><FwSkeleton variant="circle" height="20px" width="20px" count={1}></FwSkeleton></div>
                </div>
            </div>
            </div>
        )}
        <div className="flex space-x-2 pl-2">
            <div className="w-8/12 mt-2">
                    <FwSkeleton variant="rect" height="20px" count={1}></FwSkeleton>
            </div>
            <div className="w-4/12 mt-2 ml-4">
                    <div className="float-right pr-2"><FwSkeleton variant="circle" height="20px" width="20px" count={1}></FwSkeleton></div>
            </div>
        </div>
        { isDetail === 'true' && ( <div className="mt-4"></div>)}
        <div className="flex space-x-2 mt-1">
            <div className="w-4/12 mt-3">
                    <FwSkeleton variant="rect" height="38px" count={1}></FwSkeleton>
            </div>
            <div className="w-4/12 mt-3">
                    <FwSkeleton variant="rect" height="38px" count={1}></FwSkeleton>
            </div>
            <div className="w-4/12 mt-3">
                    <FwSkeleton variant="rect" height="38px" count={1}></FwSkeleton>
            </div>
        </div>
    </div>
  )
}

export default OrderSkeleton