import React, { useState, useEffect, useCallback } from 'react';
import { FwButton, FwInput } from '@freshworks/crayons/react';
import { useTranslation } from 'react-i18next';

const OrderSearch = ({ onSearch, initialSearchParameters = {} }) => {
    const { t } = useTranslation();
    const [orderId, setOrderId] = useState(initialSearchParameters.orderId || '');
    const [externalOrderId, setExternalOrderId] = useState(initialSearchParameters.externalOrderId || '');
    const [addressData, setAddressData] = useState(initialSearchParameters.addressData || '');

    useEffect(() => {
        setOrderId(initialSearchParameters.orderId || '');
        setExternalOrderId(initialSearchParameters.externalOrderId || '');
        setAddressData(initialSearchParameters.addressData || '');
    }, [initialSearchParameters]);

    const searchOrders = useCallback(() => {
        onSearch({ orderId, externalOrderId, addressData });
    }, [orderId, externalOrderId, addressData, onSearch]);

    useEffect(() => {
       
        const handleKeyDown = (event) => {
            if (event.key === 'Enter' && event.target.classList.contains('searchInput')) {
                searchOrders();
            }
        };

        window.addEventListener('keydown', handleKeyDown);
        return () => window.removeEventListener('keydown', handleKeyDown);
    }, [searchOrders]);

    return (
        <div className="p-0.5">
            <FwInput
                className="searchInput"
                label={t('search_Order_ID')}
                clearInput
                data-test-id="orderId"
                state="normal"
                value={orderId}
                onFwInput={e => setOrderId(e.target.value)}
                onFwInputClear={e => setOrderId('')}
            />
            <FwInput
                className="searchInput"
                label={t("search_External_Order_ID")}
                clearInput
                data-test-id="external-orderId"
                value={externalOrderId}
                onFwInput={e => setExternalOrderId(e.target.value)}
                onFwInputClear={e => setExternalOrderId('')}
            />
            <FwInput
                className="searchInput"
                label={t("search_Address_Data")}
                data-test-id="address-data"
                clearInput
                value={addressData}
                onFwInput={e => setAddressData(e.target.value)}
                onFwInputClear={e => setAddressData('')}
            />
            <FwButton data-test-id="search-button" className="pt-3 pb-3 w-full" color="secondary" onClick={searchOrders}>
                {t('search')}
            </FwButton>
        </div>
    );
};

export default OrderSearch;
