import React, {useEffect, useState, useRef} from 'react';
import { useTranslation } from 'react-i18next';
import iconAddComment from '../../assets/icon_add_comment.svg';
import { FwTooltip, FwModal, FwButton, FwTag, FwModalContent } from "@freshworks/crayons/react";
import { use } from 'i18next';

const OrderTags = ({orderId, orderTags, onAddOrderTags, client}) => {

    const { t } = useTranslation();

    const [open, setOpen] = useState(true);
    const [possibleTags, setPossibleTags] = useState([]);
    const [selectedOrderTags, setSelectedOrderTags] = useState([]);
    const [tagsList, setTagsList] = useState([]);
    const [originalTagsList, setOriginalTagsList] = useState([]); // used to reset tagsList
    const [tagsCount, setTagsCount] = useState(0);
    const [isActiveOrderTags, setIsActiveOrderTags] = useState(true);

    const toggleTagsList = () => {
        setIsActiveOrderTags(!isActiveOrderTags);
    };


    useEffect(() => {
        setOriginalTagsList(orderTags);
    }, []);

    useEffect(() => {
        const fetchZafSettings = async () => {
          try {
            console.log("ORDER-TAGS::orderTags", orderTags);

            const zafSettings = await client.zafClient.metadata();
            let possibleTags = zafSettings.settings.possibleTags.split('|').map(tag => {
                const tagParts = tag.split(':');
                return { id: Number(tagParts[0]), tagName: String(tagParts[1]) };
            });

            // remove possible tags that are already added to the order
            possibleTags = possibleTags.filter(possibleTag => !orderTags.some(orderTag => orderTag.id === possibleTag.id));

            setPossibleTags(possibleTags);

            console.log("ORDER-TAGS::possibleTags", possibleTags);

            const tags = orderTags || [];
            setTagsList(tags);
            setSelectedOrderTags(tags);
            setTagsCount(tags.length);
          } catch (error) {
            console.error("Failed to fetch ZAF settings:", error);
          }
        };
    
        fetchZafSettings();
    }, [orderTags]);

    const modalRef = useRef();
    const modalContentRef = useRef();

    const handleSubmitModal = async () => {
        console.log("ORDER-TAGS::selectedOrderTags", selectedOrderTags);
        onAddOrderTags(selectedOrderTags);
        modalRef.current.close();
    };

    const handleCloseModal = () => {
        setTagsList(originalTagsList);
        setTagsCount(originalTagsList.length);
        modalRef.current.close();
    };

    const handleOpenModal = async () => {

        // reset tagsList to original tags and set possible tags
        // setTagsList(originalTagsList);
        // setTagsCount(originalTagsList.length);
        // const zafSettings = await client.zafClient.metadata();
        // let possibleTags = zafSettings.settings.possibleTags.split('|').map(tag => {
        //     const tagParts = tag.split(':');
        //     return { id: Number(tagParts[0]), tagName: String(tagParts[1]) };
        // });

        // // remove possible tags that are already added to the order
        // possibleTags = possibleTags.filter(possibleTag => !orderTags.some(orderTag => orderTag.id === possibleTag.id));

        // setPossibleTags(possibleTags);

        modalRef.current.open();
    };

    const addTag = (tag) => {
        if (!selectedOrderTags.some(orderTag => orderTag.id === tag.id)) {
            setSelectedOrderTags([...selectedOrderTags, tag]);
            setTagsCount(tagsCount + 1);
            setPossibleTags(possibleTags.filter(possibleTag => possibleTag.id !== tag.id));
        }
    };

    const removeTag = (tag) => {
        setSelectedOrderTags(selectedOrderTags.filter(orderTag => orderTag.id !== tag.id));
        setTagsCount(tagsCount - 1);
        setPossibleTags([...possibleTags, tag]);
    };

    useEffect(() => {
        setTimeout(() => {
            if (modalContentRef.current && modalContentRef.current.shadowRoot) {
                const shadowRoot = modalContentRef.current.shadowRoot;
                console.log('shadowRoot for ModalContent', shadowRoot);
                const style = document.createElement('style');
                style.textContent = '.content { padding-inline: 3px !important; }';
                shadowRoot.appendChild(style);
            }
        }, 100);
    }, []); 

    return (
        <div>
            <FwModal ref={modalRef} id='tags-modal' size="large" titleText="" slider onFwClose={handleCloseModal} hideFooter="true">
                <FwModalContent ref={modalContentRef}>
                    <div>
                        <div className="text-md font-semibold mb-2">{t("add_order_tag_title")}</div>
                        <div className="mb-4  flex flex-wrap gap-1">
                            {selectedOrderTags.map((orderTag, index) => (
                                <FwTag key={index} text={orderTag.tagName} closable="true" focusable="false" onFwClosed={() => removeTag(orderTag)}></FwTag>
                            ))}
                        </div>
                        <div>
                            <div className="text-md font-semibold mb-2">{t("possible_tags")}</div>
                            <div className="flex flex-wrap gap-1">
                                {possibleTags.map((tag, index) => (
                                    <FwTag key={index} text={tag.tagName} closable="false" onClick={() => addTag(tag)}></FwTag>
                                ))}
                            </div>
                        </div>
                        <div className="flex justify-between items-center mt-5">
                            <FwButton color="text" onClick={handleCloseModal}>{t("cancel")}</FwButton>
                            <FwButton data-test-id="add-tag-submit-button" onClick={handleSubmitModal}>OK</FwButton>
                        </div>
                    </div>
                </FwModalContent>
            </FwModal>

            <div className="flex justify-between items-center mt-1 border border-gray-100 py-0.5">
                <button className={`orderDetailText font-bold text-left rounded-sm pl-0.5 flex-grow ${tagsCount ? 'text-gray-700' : 'text-gray-400 hover:text-gray-500'}`} disabled={ tagsCount === 0 ? true : false } onClick={toggleTagsList}>{t('tags')} </button>
                <div className="flex orderDetailText items-center text-left text-gray-700 font-bold  rounded-sm pl-0.5">
                    <div>
                        <FwTooltip content={t('add_tag')}>
                            <button data-test-id="add-comment-button" onClick={handleOpenModal} className="flex items-center bg-gray-100 hover:bg-gray-200 text-white font-bold py-0.5 px-1 rounded">
                                <img src={iconAddComment} alt={t('add_tag')} />
                                <span className="ml-1 text-xxs text-gray-600">{t('add_tag_btn')}</span>
                            </button>
                        </FwTooltip>
                    </div>
                    <div className={`bg-gray-50 rounded-full border-md border-gray-600 mx-1 font-bold text-l px-1.5 ${tagsCount ? 'text-gray-700 cursor-pointer' : 'text-gray-400 hover:text-gray-500'}`} onClick={toggleTagsList}>
                        {tagsCount}
                        {tagsCount > 0 ? <div className={`collapseToggle ml-2 ${isActiveOrderTags ? 'rotate' : ''}`}>▼</div> : null}
                    </div>
                </div>
            </div>

            <div className="orderListItemsWrapper">
                <div id="orderTagsList" className={`orderItemList  ${isActiveOrderTags ? 'active' : ''}`} >
                    <div key={0} className={`'mt-1'}`}>
                        <div className="mb-1 p-1 bg-slate-50 border-gray-100 border rounded-sm flex flex-wrap gap-1">
                            {tagsList.map((orderTag, index) => (
                                <FwTag key={index} text={orderTag.tagName} closable="false" focusable="false" disabled="true"></FwTag>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default OrderTags;
