import { useEffect, useState } from 'react';

const usePlentyDataFetcher = (client, {
    getPlentyStatuses,
    getPlentyOrderReferrers,
    getPlentyShippingProfiles,
    getCurrentTicket,
    getAssignedOrderId,
    setActiveTabIndex,
    getPlentyOrderById,
    generateInitialSearchParameters,
    handleSearch,
    extractOrder,
    setExtractedOrderSearch,
    detectOrderNumbers,
    getPlentyOrderByExternalId,
    setIsOrderDetectionRunning
}) => {
    // Introduce a state variable to track changes in the client 
    const [currentClient, setCurrentClient] = useState(client);
    useEffect(() => {
        // Abort controller for cleaning up async requests if the component is unmounted.
        const abortController = new AbortController();

        const fetchData = async () => {
            try {               

                // Fetch current ticket
                const ticket = await getCurrentTicket();

                // Fetch assigned order using ticket
                const assignedOrderId = await getAssignedOrderId(ticket);

                // Dont run search if there is an assigned order
                if (assignedOrderId) {
                    //Switch tab to "detail"
                    setActiveTabIndex(1);
                    await getPlentyOrderById(assignedOrderId);
                }

                // Set initial search parameters and execute search
                const searchParameters = await generateInitialSearchParameters();
                const ordersList = await handleSearch(searchParameters);

                if(ordersList.length > 0 && !assignedOrderId) {
                    const {orderFound, orderId} = extractOrder(ordersList, ticket.subject, ticket.description);
                    // const orderFound = true;
                    // const orderId = 12345678;
                    setExtractedOrderSearch({orderFound, orderId});
                    console.log("ORDER EXTRACTION RESULT:", orderFound, orderId);
                }

                // If ordersList is empty and there is no assigned order, perform a detectOrderNumbers in ticket
                if(ordersList.length === 0 && !assignedOrderId) {
                    console.log("OrderDetection - No orders found in search, trying to detect order number in ticket");
                    setIsOrderDetectionRunning(true);
                    const {orderNumberFound, orderNumber, isOrderId, isExternalOrderId} = detectOrderNumbers(ticket.subject, ticket.description);
                    console.log(`OrderDetection - Order Detection result is: orderNumberFound: ${orderNumberFound}, orderNumber:  ${orderNumber}, isOrderId: ${isOrderId}, isExternalOrderId: ${isExternalOrderId}`);
                    //if orderNumberFound and isOrderId, search for order by id
                    if(orderNumberFound && isOrderId) {
                        console.log("OrderDetection - Order number found in ticket, searching for order by id");
                        const foundOrder = await getPlentyOrderById(orderNumber);
                        console.log("OrderDetection - Found order is", foundOrder);
                        if(foundOrder) {
                            setActiveTabIndex(1);
                        }
                    }
                    //if orderNumberFound and isExternalOrderId, search for order by externalOrderId
                    if(orderNumberFound && isExternalOrderId) {
                        console.log("OrderDetection - Order number found in ticket, searching for order by externalOrderId");
                        const searchParameters = {externalOrderId: orderNumber};
                        const foundOrder = await getPlentyOrderByExternalId(searchParameters);
                        console.log("OrderDetection - Found order is", foundOrder);
                        if(foundOrder) {
                            setActiveTabIndex(1);
                        }
                    }

                    setIsOrderDetectionRunning(false);
                    console.log("OrderDetection - No order number found in ticket");
                }

                // Fetch statuses (no await because we don't need it to be resolved before the next step)
                await getPlentyStatuses();
                await getPlentyOrderReferrers();
                await getPlentyShippingProfiles();

            } catch (error) {
                console.error('An error occurred while fetching data:', error);
            }
        };

        // Execute the fetchData function
        fetchData();

        // Cleanup logic
        return () => {
            abortController.abort();
        };
    }, [currentClient]); // Listening on currentClient to avoid endless loop

    // Return the setCurrentClient so the caller can trigger the fetchData when client changes
    return setCurrentClient;
};

export default usePlentyDataFetcher;
