import {getCountryCode} from './MappingHelper';

const formatDate = (dateString) => {
    const options = { year: "numeric", month: "short", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
};

const formatOrderItemAmount = (orderItem) => {
    const itemAmount = orderItem.amounts && orderItem.amounts.find(amount => amount.isSystemCurrency);
    const priceGross = itemAmount ? itemAmount.priceGross.toFixed(2) : '-';
    return itemAmount ? `${priceGross} ${itemAmount.currency}` : '';
}

const formatOrderAmount = (order) => {
    const itemAmount = order.amounts && order.amounts.find(amount => amount.isSystemCurrency);
    const priceGross = itemAmount ? itemAmount.grossTotal.toFixed(2) : '-';
    return itemAmount ? `${priceGross} ${itemAmount.currency}` : '';
}

const formatPaidAmount = (order) => {
    const itemAmount = order.amounts && order.amounts.find(amount => amount.isSystemCurrency);
    const priceGross = itemAmount ? itemAmount.paidAmount.toFixed(2) : '-';
    return itemAmount ? `${priceGross} ${itemAmount.currency}` : '';
}

const formatOrderAddress = (address) => {

    const namePart = [address.name1, address.name2, address.name3, address.name4]
        .filter(Boolean)
        .join(' ');

    const addressPart = [address.address1, address.address2, address.address3, address.address4]
        .filter(Boolean)
        .join(', ');

    const cityPart = [address.postalCode, address.town]
        .filter(Boolean)
        .join(' ');

    const countryPart = getCountryCode(address.countryId);

    return `${namePart} ${addressPart} ${cityPart} ${countryPart}`;
}



export { formatDate, formatOrderItemAmount, formatOrderAmount, formatOrderAddress, formatPaidAmount };