import React, { useState, useEffect, useCallback, useRef } from "react";
import { FwButton, FwTooltip } from "@freshworks/crayons/react";
import { useTranslation } from 'react-i18next';
import iconPlenty from '../assets/icon_plenty.svg';
import iconLink from '../assets/icon_link.svg';
import iconLinked from '../assets/icon_linked.svg';
import iconEye from '../assets/icon_eye.svg';
import iconRefresh from '../assets/icon_refresh.svg';
import DOMPurify from 'dompurify';
import OrderComments from "./order/OrderComments";
import OrderTags from "./order/OrderTags";
import {formatDate, formatOrderItemAmount} from './utils/FormatHelper';
import {getOrderReferences} from './utils/MappingHelper';
import OrderStatus from "./order/OrderStatus";
import OrderShippingPackages from "./order/OrderShippingPackages";
import OrderBody from "./order/OrderBody";

const OrderListItem = ({ mode, order, onViewOrder, onAssignOrder, onUnAssignOrder, client, statuses, orderReferrers, onOrderUpdate, shippingProfiles, onSetReferencesList, assignedOrderId, webstores }) => {
    const { t } = useTranslation();

    const getPropertyValue = (typeId) => {
        const property = order.properties && order.properties.find(p => p.typeId === typeId);
        return property ? property.value : null;
    }

    const [isActiveOrderItems, setIsActiveOrderItems] = useState(true);
    const toggleOrderItemList = () => {
        setIsActiveOrderItems(!isActiveOrderItems);
    };

    useEffect(() => {
        if(mode !== 'search') {
            setIsActiveOrderItems(true);
        }
    }, []);

    const [isActiveOrderReferences, setIsActiveOrderReferences] = useState(false);
    const toggleOrderReferencesList = () => {
        setIsActiveOrderReferences(!isActiveOrderReferences);
    };

    const [referencesList, setReferencesList] = useState([]);
    const [referencesCount, setReferencesCount] = useState(0);



    useEffect(() => {

        const allReferences = getOrderReferences(order);

        // Set the references list and count
        setReferencesList(allReferences);
        if(mode === 'detail') {
            onSetReferencesList(allReferences);
        }
        setReferencesCount(allReferences.length);

    }, [order]);


    //Sort orderItems whereby type 6 (shipping costs) is at the end of the list
    const orderItems = order.orderItems.sort((a, b) => { return a.typeId === 6 ? 1 : -1 });
    const orderItemsCount = orderItems.filter((orderItem) => orderItem.typeId !== 6).length;

    const [orderShippingPackages, setOOrderShippingPackages] = useState(order.shippingPackages || []);
    const [orderComments, setOrderComments] = useState(order.comments || []);
    const [orderTags, setOrderTags] = useState(order.tags || []);

    const handleAddOrderTags = useCallback((tags) => {

        const addOrderTags = async () => {
            try {

                console.log("ORDER-TAGS::addOrderTags request payload", tags);

                const tagIds = tags.map(tag => tag.id).join(',');
                const orderId = order.id;

                const addOrderTagsResponse = await client.request.invoke('addOrderTags', { orderId, tagIds });
                console.log("ORDER-TAGS::addOrderTags response", addOrderTagsResponse);
                setOrderTags(addOrderTagsResponse.response.tags);

                //await client.interface.trigger("showNotify", { type: "success", message: t("tags_added") });

            } catch (error) {
                await client.interface.trigger("showNotify", { type: "danger", message: error.message || t("something_went_wrong") });
            }
        };

        addOrderTags();

    }, []);

    const handleAddOrderComment = useCallback((orderComment) => {

        const addOrderComment = async () => {
            try {
                const currentUserName = await client.zafClient.get('currentUser').then(function(userData) { return userData.currentUser.name; });
                orderComment.comment = DOMPurify.sanitize(orderComment.comment);
                const unencodedOrderComment = `${currentUserName}: ${orderComment.comment}`;
                const encodedOrderComment =`${currentUserName}: ${encodeURIComponent(orderComment.comment)}`;
                orderComment.comment = encodedOrderComment;
                console.log("ORDER-COMMENTS::addOrderComment request payload", orderComment); 

                const addOrderCommentResponse = await client.request.invoke('addOrderComment', orderComment);
                const orderCommentNew = { createdAt: formatDate(new Date()), text: unencodedOrderComment};
                setOrderComments([orderCommentNew, ...orderComments]);
                //setOrderComments([addOrderCommentResponse.response, ...orderComments]); t("something_went_wrong")
                await client.interface.trigger("showNotify", { type: "success", message: t("comment_added") });
            } catch (error) {
                await client.interface.trigger("showNotify", { type: "danger", message: error.message || t("something_went_wrong") });
            }
        };

        addOrderComment();

    }, []);

    const handleOrderStatusChange = useCallback(({ orderId, statusId }) => {
        console.log("Received order status in parent: ", orderId, statusId);

        const updateOrderStatus = async () => {
            try {
                const updateOrderStatusResponse = await client.request.invoke('updateOrderStatus', { orderId, statusId });

                const updatedOrder = {
                    ...order,
                    statusId: statusId,
                };
                onOrderUpdate(updatedOrder);

                await client.interface.trigger("showNotify", { type: "success", message: t("order_status_updated") });

            } catch (error) {
                await client.interface.trigger("showNotify", { type: "danger", message: error.message || t("something_went_wrong") });
                console.error("Error updating order status: ", error);
            }
        };

        updateOrderStatus();

    }, []);

    //openPlentyLink in a new tab. Get plentyId from iparams
    const openPlentyLink = async (type, id1, id2 = null) => {

        const zafSettings = await client.zafClient.metadata();
        const plentyId = zafSettings.settings.plentyId;
        const plentyCloudIsIreland = zafSettings.settings.plentyCloudIsIreland;
        const plentyCloud = plentyCloudIsIreland ? 'ie' : 'de';

        let path = '';
        switch (type) {
            case 'order':
                path = `/plenty/terra/order/order-ui/${id1}`;
                break;
            case 'variation':
                path = `/plenty/terra/item/my-item/${id1}/${id2}`;
                break;
            default:
                break;
        }

        const url = `https://plentymarkets-cloud-${plentyCloud}.com/${plentyId}${path}`;
        window.open(url, '_blank');

    }

    return (
        <div className="mx-0 my-1" data-test-id="OrderListItem-component">
            <div className={`rounded-md overflow-hidden bg-white mb-4 border ${assignedOrderId === order.id ? "border-green-200" : "border-gray-200"}`}>
                <div className={`p-2 flex cardHeader ${assignedOrderId === order.id ? "bg-green-200" : "bg-gray-100"}`}>
                    <div className="w-1/2 text-center">
                        <p data-test-id="order-id" className="header-text text-gray-800">
                            {order.id}
                        </p>
                    </div>
                    <div className="w-1/2 text-center">
                        {order.createdAt && <p className="header-text text-gray-800">{formatDate(order.createdAt)}</p>}
                    </div>
                </div>


                <div className="w-full flex justify-evenly items-stretch bg-gray-100 pb-0 px-0">
                        <OrderStatus order={order} onOrderStatusChange={handleOrderStatusChange} statuses={statuses} client={client} />
                    </div>
                

                <div className="px-1.5 pt-1 cardBody">
                    {/* ORDER BODY */}
                    {order && (<OrderBody order={order} orderReferrers={orderReferrers} webstores={webstores} mode={mode} shippingProfiles={shippingProfiles} onViewOrder={onViewOrder} />)}
                    {/* ORDER BODY */}
                    {/* <div className="flex justify-between orderDetailText">
                        <span className="w-1/2 text-gray-700 ">{t('Order Total')}</span>
                        <span className="w-1/2 text-gray-800 text-right font-bold">
                            {order.amounts && order.amounts.find((amount) => amount.isSystemCurrency).grossTotal}{" "}
                            {order.amounts && order.amounts.find((amount) => amount.isSystemCurrency).currency}
                        </span>
                    </div>
                    {getPropertyValue(7) && (
                        <div className="flex justify-between orderDetailText">
                            <span className="w-1/2 text-gray-700">{t('Ext. OrderId')}</span>
                            <span className="w-1/2 text-gray-800 text-right font-bold">{getPropertyValue(7)}</span>
                        </div>
                    )}
                    {order.warehouseSender && (
                        <div className="flex justify-between orderDetailText">
                            <span className="w-1/2 text-gray-700">{t('Warehouse Sender')}</span>
                            <span className="w-1/2 text-gray-800 text-right font-bold">{order.warehouseSender.name}</span>
                        </div>
                    )}

                    {email && (
                        <div className="flex justify-between orderDetailText">
                            <span className="w-1/2 text-gray-700">{t('Email')}</span>
                            <span className="w-1/2 text-gray-800 text-right font-bold">{email}</span>
                        </div>
                    )} */}

                   


                    {/* OrderItem List */}



                    <button className="orderDetailText border border-gray-100 mt-1 font-bold text-left w-full rounded-sm pl-0.5 py-1 text-gray-700" onClick={toggleOrderItemList}>
                        {t('Order_Items')}
                        <span className="bg-gray-50 rounded-full text-gray-700 border-md border-gray-600 mx-1 font-bold text-l px-1.5 float-right">{orderItemsCount}<div className={`ml-2 collapseToggle ${isActiveOrderItems ? 'rotate' : ''}`}>▼</div></span>
                    </button>


                    {/* <button className="orderDetailText text-gray-700 font-bold text-left w-full" onClick={toggleOrderItemList}>
                        {t('Order Items:')} 
                        <span className="bg-gray-100 rounded-full text-gray-700 border-md border-gray-600 mx-1 font-bold text-l px-1.5 float-right">{orderItemsCount}<div className={`ml-2 collapseToggle ${isActiveOrderItems ? 'rotate' : ''}`}>▼</div></span>
                    </button> */}
                    {/* <div className="border-t border-gray-100 mb-2 divider"></div> */}
                    <div className="orderListItemsWrapper">
                        <div id="orderItemList" className={`orderItemList ${isActiveOrderItems ? 'active' : ''}`} >
                            {orderItems.map((orderItem, index) => (
                                <div key={index} className={`bg-slate-50 border-gray-100 border rounded-sm mb-1 ${index === 0 ? 'mt-1' : ''}`}>
                                    <div className={`flex p-1 `}>
                                        {orderItem.variation && (
                                             <div data-test-id="item-name-with-link" className="w-7/12 orderDetailText text-gray-700 cursor-pointer hover:underline" onClick={() => openPlentyLink('variation', orderItem.variation.itemId, orderItem.variation.id)}>{orderItem.orderItemName}</div>
                                        )}


                                       

                                        {!orderItem.variation && (
                                        <div data-test-id="item-name-without-link" className="w-7/12 orderDetailText text-gray-700">{orderItem.orderItemName}</div>
                                        )}

                                        <div data-test-id="item-price" className="w-5/12 text-right orderDetailTextSmall">
                                            {orderItem.quantity} {" x "}
                                            {/* {orderItem.amounts && orderItem.amounts.find((amount) => amount.isSystemCurrency).priceGross}{" "} */}
                                            {formatOrderItemAmount(orderItem)}
                                        </div>

                                    </div>

                                    <div className="flex ml-1">
                                    { (orderItem.variation && orderItem.variation.number != '') && ( 
                                            <div data-test-id="item-name-with-link" className="w-1/2 orderDetailText text-gray-700">VarNr: {orderItem.variation.number}</div>
                                            )
                                        }

                                        { (orderItem.variation && orderItem.variation.model != '') && ( 
                                            <div data-test-id="item-name-with-link" className="w-1/2 orderDetailText text-gray-700 text-right">Modell: {orderItem.variation.model}</div>
                                            )
                                        }
                                    </div>

                                    {index != orderItems.length - 1 && (
                                        <div className="mb-1"></div>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                    {/* OrderItem List */}


                     {/* Order References */}
                     <div className="mt-1"></div>
                    {/* { mode !== 'search' && referencesCount > 0 && ( */}
                        <div>
                            <button className={`orderDetailText border border-gray-100 mt-1 font-bold text-left w-full rounded-sm pl-0.5 py-1 ${referencesCount ? 'text-gray-700' : 'text-gray-400 hover:text-gray-500'}`} onClick={toggleOrderReferencesList} disabled={ referencesCount === 0 ? true : false }>
                                {t('Order_References')}
                                <span className={`bg-gray-50 rounded-full border-md border-gray-600 mx-1 font-bold text-l px-1.5 float-right ${referencesCount ? 'text-gray-700' : 'text-gray-400 hover:text-gray-500'}`}>{referencesCount}
                                    { referencesCount > 0 && (<div className={`ml-2 collapseToggle ${isActiveOrderReferences ? 'rotate' : ''}`}>▼</div>)} 
                                </span>
                            </button>
                            <div className="orderListItemsWrapper">
                                <div className={`orderItemList ${isActiveOrderReferences ? 'active' : ''}`} >
                                    <div data-test-id="item-reference-subitem" className={`flex p-1 bg-slate-50 border-gray-100 border rounded-sm mb-1`}>
                                        <div className="orderDetailTextSmall text-gray-700">
                                            {referencesList.map((orderReference, index) => (
                                                <span key={orderReference.referenceOrderId}>
                                                    {orderReference.referenceOrderId}
                                                    {index !== referencesList.length - 1 ? ', ' : ''}
                                                </span>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    {/* )} */}
                    {/* Order References */}




                    {/* Order Shipping Packages */}
                    { mode !== 'search' && (<OrderShippingPackages orderShippingPackages={orderShippingPackages} shippingProfiles={shippingProfiles} mode={mode} order={order} /> )}
                    {/* Order Shipping Packages */}

                    {/* Order Comments */}
                    { mode !== 'search' && ( <OrderComments orderId={order.id} orderComments={orderComments} onAddOrderComment={handleAddOrderComment} /> )}
                    {/* Order Comments */}

                    {/* Order Tags */}
                    { mode !== 'search' && ( <OrderTags orderId={order.id} orderTags={orderTags} client={client} onAddOrderTags={handleAddOrderTags} /> )}
                    {/* Order Tags */}

                </div>
                <div className="p-1 pt-0 mt-3 mb-0.5 flex cardFooter">
    
                    <div className="w-full flex justify-end space-x-0.5">
                    { mode !== 'referenced' && (
                        <div className="flex-1">
                            <FwTooltip content={ mode === 'search' ? t('open_details') :  t('Refresh')}>
                                <button onClick={() => onViewOrder(order.id)} data-test-id="open-order-btn" className="bg-slate-600 w-full hover:bg-slate-500 text-white font-bold py-2 px-3 rounded flex-grow flex items-center justify-center">
                                { mode === 'search' && (<img src={iconEye} alt="view" />)}
                                { mode === 'detail' && (<img src={iconRefresh} alt="refresh" />)}
                                </button>
                             </FwTooltip>
                        </div>
                    )}
                        <div className="flex-1">
                        <FwTooltip content={t('Open_in_plentymarkets')}>
                                <button onClick={() => openPlentyLink('order', order.id)} className="bg-slate-600 w-full hover:bg-slate-500 text-white font-bold py-2 px-3 rounded flex-grow flex items-center justify-center">
                                    <img src={iconPlenty} alt={t('Open_in_plentymarkets')} />
                                </button>
                            </FwTooltip>
                        </div>
                        <div className="flex-1">
                        {/* <FwTooltip content={ assignedOrderId === order.id ? t('Linked_with_this_ticket') : t('Link_to_ticket')  }>
                                <div className="h-full">
                                <button onClick={() => onAssignOrder(order.id)} disabled={ assignedOrderId === order.id ? true : false } className={` w-full text-white font-bold py-2 px-3 rounded flex-grow flex items-center justify-center ${assignedOrderId === order.id ? 'bg-green-200' : 'bg-slate-600 hover:bg-slate-500'}`} >
                                    { assignedOrderId == order.id ?? order.id ? <img src={iconLinked} alt="linked" /> : <img src={iconLink} alt="link" />}
                                </button>
                                </div>
                        </FwTooltip> */}

                        { assignedOrderId != order.id && (
                            <FwTooltip content={t('Link_to_ticket') }>
                            <div className="h-full">
                                <button onClick={() => onAssignOrder(order.id)} className={` w-full text-white font-bold py-2 px-3 rounded flex-grow flex items-center justify-center bg-slate-600 hover:bg-slate-500`} >
                                        <img src={iconLink} alt="link" />
                                </button>
                            </div>
                         </FwTooltip>
                        )}

                        { assignedOrderId === order.id && (
                            <FwTooltip content={t('Linked_with_this_ticket')}>
                            <div className="h-full">
                            <button onClick={() => onUnAssignOrder(order.id)} className={` w-full text-white font-bold py-2 px-3 rounded flex-grow flex items-center justify-center bg-green-300 hover:bg-red-100`} >
                                <img src={iconLinked} alt="linked" /> 
                            </button>
                            </div>
                         </FwTooltip>
                        )}
                        


                        </div>
                        
                    </div>  

                </div>
            </div>
        </div>
    );
};

export default OrderListItem;

