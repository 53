import React, {useEffect, useState, useRef} from 'react'
import { useTranslation } from 'react-i18next';
import iconAddComment from '../../assets/icon_add_comment.svg';
import { FwTooltip, FwModal, FwButton, FwTextarea, FwSelect, FwSelectOption, FwModalTitle, FwModalContent, FwModalFooter  } from "@freshworks/crayons/react";
import {formatDate} from '../utils/FormatHelper';
import DOMPurify from 'dompurify';
import ReactDOM from "react-dom";

const OrderComments = ({orderId, orderComments, onAddOrderComment}) => {

    const { t } = useTranslation();

    //variable open = true
    const [open, setOpen] = useState(true);


    const [commentsList, setCommentsList] = useState([]);
    const [commentsCount, setCommentsCount] = useState(0);
    const [isActiveOrderComments, setIsActiveOrderComments] = useState(true);
    const toggleOrderCommentsList = () => {
        setIsActiveOrderComments(!isActiveOrderComments);
    };

    useEffect(() => {
        let comments = orderComments || [];

        comments = comments.sort((a, b) => {
            return new Date(b.createdAt) - new Date(a.createdAt);
        });

        setCommentsList(comments);
        setCommentsCount(comments.length);
    }, [orderComments]);

    const modalRef = useRef();
    const textareaRef = useRef();
    const modalContentRef = useRef();

    const handleSubmitModal = async () => {
        const comment = textareaRef.current ? textareaRef.current.value : '';

        const orderComment = { 'orderId': orderId, 'comment': comment };
        onAddOrderComment(orderComment);
        modalRef.current.close();
    };

    const handleCloseModal = () => {
        modalRef.current.close();
    };

    const handleOpenModal = () => {
        modalRef.current.open();
        // Empty the textarea
        textareaRef.current.value = '';
    };


    useEffect(() => {
        setTimeout(() => {
        if (modalContentRef.current && modalContentRef.current.shadowRoot) {
            const shadowRoot = modalContentRef.current.shadowRoot;
            console.log('shadowRoot for ModalContent', shadowRoot);
            const style = document.createElement('style');
            style.textContent = '.content { padding-inline: 3px !important; }';
            shadowRoot.appendChild(style);
        }
        }, 100);
    }, []); 


  return (
    <div>
        <FwModal ref={modalRef} id='comments-modal' size="large" titleText="" slider onFwClose={handleCloseModal} hideFooter="true">
                <FwModalContent ref={modalContentRef}>
                    <div>
                        <div className="text-lg font-bold mb-4">{t("add_order_comment_title")}</div>
                        <FwTextarea data-test-id="add-comment-textarea" ref={textareaRef} cols={29} rows={8}
                                    placeholder={t('add_order_comment_placeholder')}
                                    state="normal">
                        </FwTextarea>
                        <div className="flex justify-between items-center mt-5">
                            <FwButton color="text" onClick={handleCloseModal}>{t("cancel")}</FwButton>
                            <FwButton data-test-id="add-comment-submit-button" onClick={handleSubmitModal}>OK</FwButton>
                        </div>

                    </div>
                </FwModalContent>
            </FwModal>

            <div className="flex justify-between items-center mt-1 border border-gray-100 py-0.5">
                <button className={`orderDetailText font-bold text-left rounded-sm pl-0.5 flex-grow ${commentsCount ? 'text-gray-700' : 'text-gray-400 hover:text-gray-500'}`} disabled={ commentsCount === 0 ? true : false } onClick={toggleOrderCommentsList}>{t('comments')} </button>
                <div className="flex orderDetailText items-center text-left text-gray-700 font-bold  rounded-sm pl-0.5">
                    <div>
                        <FwTooltip content={t('add_comment')}>
                            <button data-test-id="add-comment-button" onClick={handleOpenModal} className="flex items-center bg-gray-100 hover:bg-gray-200 text-white font-bold py-0.5 px-1 rounded">
                                <img src={iconAddComment} alt={t('add_comment')} />
                                <span className="ml-1 text-xxs text-gray-600">{t('add_comment_btn')}</span>
                            </button>
                        </FwTooltip>
                    </div>
                    <div className={`bg-gray-50 rounded-full border-md border-gray-600 mx-1 font-bold text-l px-1.5 ${commentsCount ? 'text-gray-700 cursor-pointer' : 'text-gray-400 hover:text-gray-500'}`} onClick={toggleOrderCommentsList}>
                            {commentsCount}
                            {commentsCount > 0 ? <div className={`collapseToggle ml-2 ${isActiveOrderComments ? 'rotate' : ''}`}>▼</div> : null}
                    </div>
                </div>
            </div>

            

        <div className="orderListItemsWrapper">
            <div id="orderCommentsList" className={`orderItemList  ${isActiveOrderComments ? 'active' : ''}`} >
                {commentsList.map((orderComment, index) => (
                    <div key={index} className={`${index === 0 ? 'mt-1' : ''}`}>
                        <div className="mb-1 p-1 bg-slate-50 border-gray-100 border rounded-sm">
                            <div className="w-full orderDetailText text-gray-700">{formatDate(orderComment.createdAt)}</div>
                            <p data-test-id="item-comments-subitem" className="text-sm text-gray-600" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(orderComment.text) }}></p> 
                        </div>
                        
                    </div>
                ))}
            </div>
        </div>
    </div>
  )
}

export default OrderComments
