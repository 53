import React, {useEffect, useState, useRef} from 'react'
import { useTranslation } from 'react-i18next';
import iconAhref from '../../assets/icon_ahref.svg';
import iconTracking from '../../assets/icon_tracking.svg';
import { FwTooltip, FwModal, FwButton, FwTextarea, FwSelect, FwSelectOption, FwModalTitle, FwModalContent, FwModalFooter  } from "@freshworks/crayons/react";



const OrderShippingPackages = ({orderId, orderShippingPackages, mode, order, shippingProfiles}) => {

    const { t } = useTranslation();
    const [shippingPackagesList, setShippingPackagesList] = useState([]);
    const [shippingPackagesCount, setShippingPackagesCount] = useState(0);
    const [isActiveShippingPackages, setIsActiveShippingPackages] = useState(false);
    const toggleShippingPackagesList = () => {
        setIsActiveShippingPackages(!isActiveShippingPackages);
    };

    useEffect(() => {
        let shippingPackages = orderShippingPackages || [];
        //filter out empty shipping packages with empty packageNumber
        shippingPackages = shippingPackages.filter(shippingPackage => shippingPackage.packageNumber);

        setShippingPackagesList(shippingPackages);
        setShippingPackagesCount(shippingPackages.length);

        if(mode !== 'search' && shippingPackages.length > 0) {
            setIsActiveShippingPackages(true);
        }

    }, [orderShippingPackages]);

    const getDeliveryAddress = () => {
        //typeid 2 = Lieferadresse
        const address = order.addresses && order.addresses.find(a => a.pivot.typeId === 2);
        return address ? address : null;
    }

    const getPropertyValue = (typeId) => {
        const property = order.properties && order.properties.find(p => p.typeId === typeId);
        return property ? property.value : null;
    }

    const createTrackingLinkCombined = (shippingPackagesList) => {

        const packageNumbers = shippingPackagesList.map((shippingPackage) => shippingPackage.packageNumber).join(',');

        const shippingProfileId = getPropertyValue(2);
        if(!shippingProfileId) return null;
        const shippingProfile = shippingProfiles && shippingProfiles.find(sp => sp.id == shippingProfileId);
        if(!shippingProfile) return null;
        const trackingUrl = shippingProfile.trackingUrl;
        if(!trackingUrl) return null;

        console.log('trackingUrl', trackingUrl);

        const deliveryAddress = getDeliveryAddress();
        if(!deliveryAddress) return null;

        let trackingUrlWithParams = trackingUrl.replace('[PaketNr]', packageNumbers);

        if(deliveryAddress.postalCode) {
            trackingUrlWithParams = trackingUrlWithParams.replace('[PLZ]', deliveryAddress.postalCode);
        }

        return (
            <FwTooltip content={t('track_package')}>
                <a href={trackingUrlWithParams} target="_blank" rel="noopener noreferrer">
                    <img className="mr-2" src={iconAhref} alt={t('track_package')} /> 
                </a>
            </FwTooltip>
        );

    }

    const createTrackingLink = (packageNumber) => {

        const shippingProfileId = getPropertyValue(2);
        if(!shippingProfileId) return null;
        const shippingProfile = shippingProfiles && shippingProfiles.find(sp => sp.id == shippingProfileId);
        if(!shippingProfile) return null;
        const trackingUrl = shippingProfile.trackingUrl;
        if(!trackingUrl) return null;

        console.log('trackingUrl', trackingUrl);

        const deliveryAddress = getDeliveryAddress();
        if(!deliveryAddress) return null;

        let trackingUrlWithParams = trackingUrl.replace('[PaketNr]', packageNumber);

        if(deliveryAddress.postalCode) {
            trackingUrlWithParams = trackingUrlWithParams.replace('[PLZ]', deliveryAddress.postalCode);
        }

        return (
            <FwTooltip content={t('track_package')}>
                <a href={trackingUrlWithParams} target="_blank" rel="noopener noreferrer">
                    <img className="mr-2" src={iconAhref} alt={t('track_package')} /> 
                </a>
            </FwTooltip>
        );
       
    }




  return (
    <div>
        <button className={`orderDetailText border border-gray-100 mt-1 font-bold text-left w-full rounded-sm pl-0.5 py-1 ${shippingPackagesCount ? 'text-gray-700' : 'text-gray-400 hover:text-gray-500'}`} onClick={toggleShippingPackagesList} disabled={ shippingPackagesCount === 0 ? true : false }>
            {t('Shipping_packages')}
            <span 
            className={`bg-gray-50 rounded-full  border-md border-gray-600 mx-1 
            font-bold text-l px-1.5 float-right`}
            >{shippingPackagesCount}

            { shippingPackagesCount > 0 && (<div className={`ml-2 collapseToggle ${isActiveShippingPackages ? 'rotate' : ''}`}>▼</div>)}     
            </span>
        </button>
        <div className="orderListItemsWrapper">
            <div className={`orderItemList  ${isActiveShippingPackages ? 'active' : ''}`} >
                
                <div key={0} className={`mt-1`}>
                        <div className={`flex p-1 bg-slate-50 border-gray-100 border rounded-sm mb-1`}>
                            <div className="w-full orderDetailText text-gray-700">
                            {shippingPackagesList.map((shippingPackage) => shippingPackage.packageNumber).join(', ')}
                            </div>
                            <div>{createTrackingLinkCombined(shippingPackagesList)}</div>
                        </div>
                </div>


            </div>
        </div>
    </div>
  )
}

export default OrderShippingPackages
